<script setup lang="ts">
const isGuest = useIsGuest();
const localPath = useLocalePath();
const { open } = useFunrizeModals();
const loginGuard = useLoginGuard();
const { t } = useT();

const { isCanPlay } = useBingoData({ immediate: false });

const subTitle = computed(() => (isGuest.value ? t("Play games to collect bingo balls") : t("Win up to")));
const btnText = computed(() => {
	if (isGuest.value) {
		return t("Get 1st ball instantly");
	}
	if (isCanPlay.value) {
		return t("Play bingo lotto");
	}
	return t("Play now");
});

const handleClick = () => {
	loginGuard({
		success: () => {
			if (isCanPlay.value) {
				open("LazyOModalBingoPlay");
				return;
			}
			navigateTo(localPath("/issues/popular-games/"));
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
};
</script>

<template>
	<ACard
		:imgAttrs="{
			width: 328,
			height: 257,
			src: '/nuxt-img/bingo/promo-bg.webp',
			format: 'avif',
			loading: 'lazy',
			alt: 'card-bingo'
		}"
		height="257px"
		class="card-bingo"
		@click="handleClick"
	>
		<template #default>
			<ABadge background="var(--carrara)" variant="skew" class="top-left" autosize>
				<AText variant="topeka" :modifiers="['bold', 'uppercase']">{{ t("Free to play") }}</AText>
			</ABadge>
		</template>
		<template #top>
			<div class="block">
				<AText variant="ternopil" :modifiers="['normal', 'uppercase']" class="title">
					{{ t("Bingo lotto") }}
				</AText>
				<AText variant="topeka" :modifiers="['bold', 'uppercase']" class="sub-title" as="div">
					{{ subTitle }}
				</AText>
				<div class="prize-pool">
					<MPrizeFund variant="coins" icon="16/coins" :iconSize="28">
						<AText :size="32" :modifiers="['extrabold']">
							{{ t("1,000,000") }}
						</AText>
					</MPrizeFund>
					<MPrizeFund variant="entries" icon="20/entries" :icon-size="28">
						<AText :size="32" :modifiers="['extrabold']">
							{{ t("10,000") }}
						</AText>
					</MPrizeFund>
				</div>
			</div>
		</template>
		<template #bottom>
			<AButton variant="primary" size="md">
				<AText :size="16" :modifiers="['uppercase']">{{ btnText }}</AText>
			</AButton>
			<MBingoProgress v-if="!isGuest || !isCanPlay" class="sidebar-progress" />
		</template>
	</ACard>
</template>

<style scoped lang="scss">
.card-bingo {
	.sub-title {
		max-width: 220px;
	}

	.prize-pool {
		display: flex;
		flex-direction: column;
	}

	.title {
		position: absolute;
		top: 42px;
		left: 20px;
		max-width: 100%;
	}

	.top-left {
		position: absolute;
		top: 0;
		left: 0;
	}

	.sidebar-progress {
		position: absolute;
		max-width: 240px;
		display: flex;
		gap: 8px;
		align-items: center;
		bottom: 6px;
		left: 20px;
	}

	button {
		width: 240px;
	}
}
</style>
